'use client'

import { createPayment } from '@/lib/payment/actions'
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { ConfigContext } from '@/components/common/ConfigContext'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LoadingIndicator from '@/components/elements/LoadingIndicator'
import { PayNowButton } from '@/components/pages/checkout/PayNowButton'
import { CheckoutErrorMessage } from '@/components/pages/checkout/CheckoutErrorMessage'
import { LOCALE_MAPPING, PAYMENT_TO_CODE } from '@/lib/payment/constants'
import { PaymentOverview } from '@/components/pages/checkout/common/PaymentOverview'
import { useTranslations } from 'next-intl'
import { mapPaymentMethod } from '@/lib/payment/utils'

export function Overview(props: { paymentMethodSelection?: ReactNode; withinIframe: boolean }) {
  const [searchParams, setSearchParams] = useState(new URLSearchParams(''))
  const locale = useContext(ConfigContext)!.locale
  const companyName = useContext(ConfigContext)!.companyNameJsonLd
  const t = useTranslations('Booking.BookingPaymentMethods')
  const buttonBlockedRef = useRef(false)
  const [buttonBlocked, setButtonBlocked] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  function handlePaymentClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    e.stopPropagation()

    setButtonBlocked(true)
    const billingAddress =
      searchParams.get('firstname')! &&
      searchParams.get('lastname')! &&
      searchParams.get('street')! &&
      searchParams.get('zip')! &&
      searchParams.get('city')! &&
      searchParams.get('country')!
        ? {
            billingAddress: {
              givenName: searchParams.get('firstname')! || '',
              familyName: searchParams.get('lastname')! || '',
              email: searchParams.get('email')! || '',
              streetAndNumber: searchParams.get('street')! || '',
              postalCode: searchParams.get('zip')! || '',
              city: searchParams.get('city')! || '',
              country: searchParams.get('country')! || '',
            },
          }
        : {}
    createPayment(
      {
        ...billingAddress,
        billingEmail: searchParams.get('email')!,
        method: searchParams.get('payment')!,
        amount: {
          value: searchParams.get('amount') || '0',
          currency: searchParams.get('currency') || 'EUR',
        },
        metadata: {
          orderId: searchParams.get('orderid')!,
          firstname: searchParams.get('firstname')!,
          lastname: searchParams.get('lastname')!,
          email: searchParams.get('email')!,
          ordertype: searchParams.get('ordertype')!,
          customernumber: searchParams.get('customernumber')!,
        },
        locale: LOCALE_MAPPING[locale],
      },
      companyName,
    )
      .then((result) => {
        if (result.checkoutUrl) {
          if (props.withinIframe) {
            window.open(result.checkoutUrl, '_blank')
            window.close()
          } else {
            window.location.href = result.checkoutUrl
          }
        } else {
          setErrorMessage(result.errorMessage!)
          buttonBlockedRef.current = false
          setButtonBlocked(false)
        }
      })
      .catch(() => setErrorMessage('An error occurred'))
  }

  useEffect(() => {
    setSearchParams(new URLSearchParams(window.location.search))
  }, [])

  // if (true) {
  if (searchParams.size === 0) {
    return (
      <div className="relative mt-[64px] flex h-[400px] items-center justify-center">
        <LoadingIndicator additionalClassName="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      </div>
    )
  }

  function translatePayment(s: string) {
    const mappedPaymentType = mapPaymentMethod(s)

    return t(`bookingPaymentMethod${PAYMENT_TO_CODE[mapPaymentMethod(mappedPaymentType)!]}`)
  }

  return (
    <div className="flex w-full flex-grow flex-col items-center gap-4">
      <PaymentOverview
        orderid={searchParams.get('orderid')!}
        firstname={searchParams.get('firstname')!}
        lastname={searchParams.get('lastname')!}
        email={searchParams.get('email')!}
        payment={translatePayment(searchParams.get('payment')!)}
        amount={searchParams.get('amount')!}
        currency={searchParams.get('currency')!}
        customerId={searchParams.get('customernumber')!}
      />
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <div className="flex w-full flex-col items-center sm:px-4">
        <PayNowButton
          onClick={handlePaymentClick}
          buttonBlockedRef={buttonBlockedRef}
          disabled={buttonBlocked}
        />
        {errorMessage && <CheckoutErrorMessage errorMessage={errorMessage} />}
      </div>
    </div>
  )
}
