import { Domain } from '../../domain-config/domain-config'
import {
  CODE_TO_PAYMENT,
  LEGACY_PAYMENT_METHODS_MAPPING,
  MolliePaymentMethodCodes,
} from '@/lib/payment/constants'

export function paymentApiKey(inputDomain: Domain): string {
  const domain = inputDomain.includes('spadreams') ? Domain.SPADREAMS_COM : inputDomain

  const domainPart = domain.toUpperCase().replace('.', '_').replace('-', '_')
  const apiKey = process.env[`PAYMENT_API_KEY_${domainPart}`]

  if (!apiKey) {
    throw new Error('API_KEY not set')
  }

  return apiKey
}

export function mapPaymentMethod(method: string): string {
  return (
    CODE_TO_PAYMENT[method as MolliePaymentMethodCodes] ||
    LEGACY_PAYMENT_METHODS_MAPPING[method] ||
    method
  )
}
