import(/* webpackMode: "eager", webpackExports: ["CurrencySelect"] */ "/vercel/path0/site/components/common/footer/CurrencySelect.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/components/pages/checkout/booking-page/booking-result/images/ayurveda-oil.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/components/pages/checkout/booking-page/booking-result/images/happy-best-ager-couple.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/components/pages/checkout/booking-page/booking-result/images/happy-best-ager.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/components/pages/checkout/booking-page/booking-result/images/infinity-pool.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/components/pages/checkout/booking-page/booking-result/images/sand-massage.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/components/pages/checkout/booking-page/booking-result/images/yoga-in-the-sun.jpeg");
;
import(/* webpackMode: "eager", webpackExports: ["Overview"] */ "/vercel/path0/site/components/pages/checkout/payment-page/Overview.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/icons/font-awesome/sharp-light/shield-check.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/site/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/site/node_modules/next/dist/client/image-component.js");
